import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import { Map, GoogleApiWrapper } from 'google-maps-react'
import mapStyles from 'assets/hint-map-style'

class MapContainer extends React.Component {
  state = {
    mapCenter: { lat: 39.8283, lng: -98.5795 }
  }
  render() {
    const { classes } = this.props
    const { mapCenter } = this.state
    return (
      <div className={classes.container}>
        <Map
          google={this.props.google}
          zoom={4}
          styles={mapStyles}
          disableDefaultUI
          initialCenter={mapCenter}
        />
      </div>
    )
  }
}

export const ContactMap = injectSheet(style)(MapContainer)

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCfvUE3boBcY-1DR4dQ3lurg2c3d39u-XY'
})(ContactMap)
