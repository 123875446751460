import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import { Location } from '@reach/router'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'
import slashes from 'assets/images/slashes.svg'
import Nav from 'components/Header/Nav'

const PageHeader = ({ title, text, classes, children }) => (
  <div className={classes.container}>
    <Location>
      {({ location }) => {
        return (
          <Helmet
            meta={[
              {
                property: 'og:image',
                content: `${location.origin}${withPrefix('/img/logo.svg')}`
              },
              { property: 'og:url', content: location.href }
            ]}
          />
        )
      }}
    </Location>
    <Nav />
    <div className={`container ${classes.innerContainer}`}>
      <h1>{title}</h1>
      <div className='row no-gutters'>
        <div className='col-12 col-md-10'>
          <h4 className={classes.content}>{children || text}</h4>
        </div>
      </div>
    </div>
    <img className={classes.headerSlashes} src={slashes} />
  </div>
)

export default injectSheet(style)(PageHeader)
