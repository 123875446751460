import { SPACING } from 'helpers/globals'

export default {
  container: {
    width: '100%',
    height: '30rem',
    marginTop: `-${SPACING.large}`,
    position: 'relative',
    zIndex: 2
  }
}
